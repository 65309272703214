<template>
  <section id="home" class="iq-main-slider main_bg p-0 iq-rtl-direction">
    <div class="slide slick-bg s-bg-1"
      :style="'background: url(' + data.image + '); padding: 100px 0 50px;width:100%; background-size:100% 100%;background-position: center center; background-repeat: no-repeat; position: relative; z-index: 1;'">
      <b-container fluid class="position-relative h-100">
        <div class="slider-inner h-100">
          <b-row class="align-items-center  h-100 iq-ltr-direction">
            <b-col xl="6" lg="12" md="12">
              <h1 class="slider-text big-title title text-uppercase" data-animation-in="fadeInLeft" data-delay-in="0.6">
                {{ data.title }}</h1>
              <p data-animation-in="fadeInUp" data-delay-in="1.2">{{ data.description }}</p>
              <div class="d-flex align-items-center r-mb-23" data-animation-in="fadeInUp" data-delay-in="1.2">
                <a href="#detail" class="btn btn-hover viewMoreBtn d-none d-md-block" id="viewMoreBtn">View More</a>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </section>
</template>

<style scoped lang="scss">
  .main_bg {
    @media (min-width:769px) {
      height:100vh !important;
    }
  }
  .slick-bg {
    background-size: 100% 100% !important;
    @media (max-width: 768px) {
      background-size:cover;
      width:100%;
      height:100% !important;
    }

    @media (min-width:769px) {
      height:100vh !important;
      background-size: cover;
    }
  }
</style>
<script>
import ApiService from "../../../../services/api"

export default {
  name: 'Home',
  components: {
  },
  mounted () {
    ApiService.getSetting().then((response) => {
      this.data = response.data.data
    })
  },
  data () {
    return {
      slideData: { title: 'Game Expert', age: '16', sesson: '2h 40m', text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.", src: require('@/assets/images/frontend/slider/slider2.jpg') },
      data: {}
    }
  }
}
</script>
