<template>
  <!-- TOP Nav Bar -->
  <header id="main-header">
    <div class="main-header">
      <b-container fluid>
        <b-row>
          <b-col sm="12">
            <nav class="navbar navbar-expand-lg navbar-light p-0">
              <a href="javascript:void(0)" class="navbar-toggler c-toggler collapsed" @click="openSidebar">
                <div class="navbar-toggler-icon" data-toggle="collapse">
                  <span class="navbar-menu-icon navbar-menu-icon--top"></span>
                  <span class="navbar-menu-icon navbar-menu-icon--middle"></span>
                  <span class="navbar-menu-icon navbar-menu-icon--bottom"></span>
                </div>
              </a>
              <router-link class="navbar-brand" to="/">
                <img class="img-fluid logo" :src="logo" alt="GameExpert" /></router-link>
              <b-collapse id="navbarSupportedContent" is-nav :visible="sidebar">
                <div class="menu-main-menu-container">
                  <ul id="top-menu" class="navbar-nav ml-auto">
                    <li class="menu-item">
                      <a @click="gotoPage('/')">Home</a>
                    </li>
                    <li v-for="(item, index) in categories" :key="index" class="menu-item">
                      <a @click="gotoPage('/category/'+item.id)">{{
                        item.name
                      }}</a>
                    </li>
                   <li class="menu-item"><a @click="gotoPage('/myVideos')">My Videos</a></li>
                  </ul>
                </div>
              </b-collapse>
              <div :class="{'navbar-right':true,'d-none':sidebar}">
                My points  : {{userPoints}}
              </div>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </header>
  <!-- TOP Nav Bar END -->
</template>
<script>
import { core, APPLOGONAME } from "../../../config/pluginInit"
import ApiService from "../../../services/api"

export default {
  name: "FrontendNav",
  props: {
    logo: { type: String, default: require("../../../assets/images/appLogo.png") },
    items: { type: Array },
    userprofile: { type: String }
  },
  data () {
    return {
      appName: APPLOGONAME,
      sidebar: false,
      categories: []
    }
  },
  mounted () {
    core.index()
    this.getCategories()
  },
  methods: {
    gotoPage (link) {
      document.getElementsByTagName("body")[0].classList.remove("nav-open")
      this.sidebar = false
      this.$router.push(link)
    },
    openSidebar () {
      if (this.sidebar === false) {
        document.getElementsByTagName("body")[0].classList.add("nav-open")
        this.sidebar = true
      } else {
        document.getElementsByTagName("body")[0].classList.remove("nav-open")
        this.sidebar = false
      }
    },
    getCategories () {
      ApiService.getCategories().then((response) => {
        this.categories = response.data.data
      })
    }
  },
  computed: {
    userPoints () {
      return this.$store.state.points
    }
  }
}
</script>

<style>
  .menu-item {
    cursor: pointer;
  }
</style>
